import {get} from 'lodash';
import {getCameras} from '../../api/sensors';
import {getSiteCloudArchiveStatus} from '../../api/cloudArchive';
import allowed, {CLOUD_ARCHIVE_ADMIN, CLOUD_ARCHIVE_USER} from './allowed';

export const getSiteCameras = async (user, siteId) => {
  const cameras = await getCameras(siteId);
  if (
    user.settings.enableCloudArchive2 &&
    allowed(user, [CLOUD_ARCHIVE_ADMIN, CLOUD_ARCHIVE_USER])
  ) {
    const cloudArchiveStatus = await getSiteCloudArchiveStatus(siteId);
    return cameras.map((c) => {
      let cloudArchive = get(cloudArchiveStatus, `cameras.${c.id}`);
      if (cloudArchive) {
        // Get the high watermarks into the correct timezone
        cloudArchive.highWatermark = cloudArchive.highWatermark.setZone(
          c.timeZone,
        );
      } else {
        cloudArchive = {};
      }

      return {
        ...c,
        cloudArchive,
      };
    });
  }
  return cameras;
};
