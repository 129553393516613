import React, {PureComponent} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import axios from 'axios';

import CurrentUserContext from '../../contexts/currentUserContext';
import {overrideComponent} from '../../types/common';
import LoadingScreen from '../loadingScreen';
import {withLogger} from '../logger';

class AuthenticatedRoute extends PureComponent {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const {navigate, redirectTo, location, path, logger} = this.props;
    try {
      const query = queryString.parse(location?.search);
      const jwtToken = query.jwt_token;
      if (jwtToken) {
        logger.info('JWT token found in query string');
        axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
      }

      const currentUser = await this.props.authenticate();
      // Make capabiliitesList a json
      const capabilitiesList =
        currentUser.capabilities && Array.isArray(currentUser.capabilities)
          ? currentUser.capabilities.reduce((acc, c) => {
              acc[c.name] = true;
              return acc;
            }, {})
          : {};
      // Define user for WalkMe
      window.walkMeUser = {
        capabilities: capabilitiesList,
        accessList: currentUser.accessListName,
        domain: currentUser.domainName,
        email: currentUser.email,
        id: currentUser.id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        persona: currentUser.personaName,
        role: currentUser.roleName,
        username: currentUser.username,
      };
      return this.setState({currentUser, loading: false});
    } catch (error) {
      const {response} = error;
      const target = location
        ? queryString.parse(location.search).target ||
          `/ui${location.pathname}${location.search || ''}`
        : path;
      if (response && [401, 403].includes(response.status)) {
        const redirectTarget = `${redirectTo}?${queryString.stringify({
          target,
        })}`;
        return navigate(redirectTarget);
      }
      // TODO:  Now what?
      throw error;
    }
  }

  render() {
    const {component: Component, ...rest} = this.props;

    const {currentUser, loading} = this.state;
    return loading ? (
      <LoadingScreen />
    ) : (
      <Route
        {...rest}
        render={(props) => (
          <CurrentUserContext.Provider
            value={{
              currentUser,
            }}
          >
            <Component {...props} />
          </CurrentUserContext.Provider>
        )}
      />
    );
  }
}

AuthenticatedRoute.propTypes = {
  component: overrideComponent.isRequired,
  authenticate: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  navigate: PropTypes.func,
  path: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

AuthenticatedRoute.defaultProps = {
  redirectTo: '/login',
  navigate: (target) => (window.location.href = target),
  path: undefined,
  location: undefined,
};

export default withLogger(AuthenticatedRoute);
