import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PageLoader from '../../shared/components/pageLoader';
import GlobalErrorPage from '../globalErrorPage';

import Users from './user';
import Groups from './groups';
import DomainRoutes from './domains';
import DelegationsRoutes from './delegations';
import Preferences from './preferences';
import {getRegisters} from '../../api/registers';
import {getAllSiteCloudArchiveSettings} from '../../api/cloudArchive';
import {getLoginMessage} from '../../api/loginMessage';
import {getOrganization, getSites} from '../../api/sites';
import {getPeopleCountConfigSites} from '../../api/intelligenceConfiguration';
import {
  getIntegrationsDomain,
  getAdapterCategoryListDomain,
  getAdaptersList,
} from '../../api/integrations';
import {getAuthConfiguration} from '../../api/auth';
import {getFullUserById} from '../../api/users';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import {
  GROUP_ADMIN,
  POS_CONFIG,
  SWITCH_USER,
  USER_ADMIN,
  DELEGATION_ADMIN,
  CLOUD_ARCHIVE_ADMIN,
  CLOUD_ARCHIVE_USER,
  ENVR_ADMIN,
  MANAGE_IN_APP_MESSAGING,
  MOTION_ALARM_ADMIN,
  INTEGRATION_ADMIN,
  SECURITY_ADMINISTRATOR,
  BI_REPORT_ADMIN,
} from '../../shared/util/allowed';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import AuthenticatedRoute from '../../shared/components/authenticatedRoute';
import AdminFrameWrapper from '../adminFrameWrapper';

const adminRoutes = ({match, currentUser}) => (
  <Switch>
    <Route path={`${match.path}/users`} component={Users} />
    <Route
      path={`${match.path}/groups`}
      render={() => (
        <CapabilitiesGuard
          allowed={[GROUP_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <Groups />
        </CapabilitiesGuard>
      )}
    />
    {currentUser.settings.enableIntegrationConfig && (
      <Route
        path={`${match.path}/integrations`}
        render={() => (
          <CapabilitiesGuard
            allowed={[INTEGRATION_ADMIN]}
            renderDefault={() => <GlobalErrorPage error="NoAuth" />}
          >
            <PageLoader
              page={() => import('./integrations')}
              domainId={currentUser.domainId}
              resources={{
                integrationList: () =>
                  getIntegrationsDomain(currentUser.domainId),
                adapterList: () => getAdaptersList(),
                categoryList: () => getAdapterCategoryListDomain(),
              }}
            />
          </CapabilitiesGuard>
        )}
      />
    )}
    <Route
      path={`${match.path}/roles/:id?`}
      render={() => (
        <CapabilitiesGuard
          allowed={[USER_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader page={() => import('./roles')} />
        </CapabilitiesGuard>
      )}
    />
    <Route
      path={`${match.path}/access/:id?`}
      render={() => (
        <CapabilitiesGuard
          allowed={[USER_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader page={() => import('./access')} />
        </CapabilitiesGuard>
      )}
    />
    <Route path={`${match.path}/preferences`} component={Preferences} />
    <Route
      path={`${match.path}/registers`}
      render={() => (
        <CapabilitiesGuard
          allowed={[POS_CONFIG]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader
            page={() => import('./registers')}
            resources={{
              registers: () => getRegisters(),
            }}
          />
        </CapabilitiesGuard>
      )}
    />
    <Route
      path={`${match.path}/domains`}
      render={() => (
        <CapabilitiesGuard
          allowed={[SWITCH_USER]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <DomainRoutes match={match} />
        </CapabilitiesGuard>
      )}
    />
    <Route
      path={`${match.path}/delegations`}
      render={() => (
        <CapabilitiesGuard
          allowed={[DELEGATION_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <DelegationsRoutes match={match} />
        </CapabilitiesGuard>
      )}
    />
    <Route
      path={`${match.path}/organization`}
      render={() => (
        <CapabilitiesGuard
          allowed={[ENVR_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader
            page={() => import('./organization')}
            resources={{
              organization: () => getOrganization(),
            }}
          />
        </CapabilitiesGuard>
      )}
    />
    <Route
      path={`${match.path}/manage-messages`}
      render={() => (
        <CapabilitiesGuard
          allowed={[MANAGE_IN_APP_MESSAGING]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader
            page={() => import('./manageLoginMessages')}
            resources={{
              messages: getLoginMessage,
            }}
          />
        </CapabilitiesGuard>
      )}
    />
    {currentUser.settings.enableCloudArchive2 && (
      <Route
        path={`${match.path}/cloud_archive_config`}
        render={() => (
          <CapabilitiesGuard
            allowed={[CLOUD_ARCHIVE_ADMIN, CLOUD_ARCHIVE_USER]}
            renderDefault={() => <GlobalErrorPage error="NoAuth" />}
          >
            <PageLoader
              page={() => import('./cloudArchiveConfig')}
              resources={{
                sites: () => getSites(),
                cloudArchiveSettings: () =>
                  getAllSiteCloudArchiveSettings(true),
              }}
            />
          </CapabilitiesGuard>
        )}
      />
    )}
    {currentUser.settings.enableAlarmSiteAnalysis && (
      <Route
        path={`${match.path}/alarm-configuration`}
        render={() => (
          <CapabilitiesGuard
            allowed={[MOTION_ALARM_ADMIN]}
            renderDefault={() => <GlobalErrorPage error="NoAuth" />}
          >
            <PageLoader
              page={() => import('./alarmConfiguration')}
              currentUser={currentUser}
            />
          </CapabilitiesGuard>
        )}
      />
    )}
    (
    <Route
      path={`${match.path}/intelligence-configuration`}
      render={() => (
        <CapabilitiesGuard
          allowed={[BI_REPORT_ADMIN]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader
            page={() => import('./intelligenceConfiguration')}
            currentUser={currentUser}
            resources={{
              peopleCountConfigs: () => getPeopleCountConfigSites(),
            }}
          />
        </CapabilitiesGuard>
      )}
    />
    <Route
      path={`${match.path}/authentication`}
      render={() => (
        <CapabilitiesGuard
          allowed={[SECURITY_ADMINISTRATOR]}
          renderDefault={() => <GlobalErrorPage error="NoAuth" />}
        >
          <PageLoader
            page={() => import('./authentication')}
            resources={{
              config: () => getAuthConfiguration(currentUser.domainId),
            }}
            currentUser={currentUser}
          />
        </CapabilitiesGuard>
      )}
    />
    <AuthenticatedRoute
      path={`${match.path}/appliances/list`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/appliances/list`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals allow-forms',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/partners`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/partners`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/cameras/makes/list`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/cameras/makes/list`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/platform/domains`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/domains`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/dvrs/makes/list`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/dvrs/makes/list`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/templates/list`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/templates/list`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/domain`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/domain`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/sku`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/sku`,
          '/app/#',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/accessList`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/accessList`,
          '',
          'allow-scripts allow-same-origin allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/posList`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/posList`,
          '',
          'allow-scripts allow-same-origin allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/exportList`}
      component={() =>
        AdminFrameWrapper(
          `${match.path}/exportList`,
          '',
          'allow-scripts allow-same-origin allow-modals allow-downloads',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <AuthenticatedRoute
      path={`${match.path}/audits`}
      component={() =>
        AdminFrameWrapper(
          `/app/#/samples/auditor`,
          '',
          'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
        )
      }
      authenticate={getFullUserById}
      redirectTo="/ui/auth/login"
    />
    <Route path="*" component={GlobalErrorPage} />
  </Switch>
);

export default withCurrentUser(adminRoutes);
