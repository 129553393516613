import React, {Fragment, useState} from 'react';
import {Grid, Button} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {PropTypes} from 'prop-types';
import EditTemplateDialog from './EditTemplateDialog';
import {useDialog} from '../../../../shared/hooks';
import ScheduleExceptionsTable from './ScheduleExceptionsTable';

const ScheduleExceptionsSection = ({snackbar}) => {
  const [tableRefresh, setTableRefresh] = useState(false);
  const [
    createTemplateDialogOpen,
    handleCreateTemplateDialogOpen,
    handleCreateTemplateDialogClose,
  ] = useDialog();

  return (
    <Fragment>
      <Grid item xs={0} md={6} lg={9} />
      <Grid item xs={12} md={6} lg={3} data-cy="create-exception-button">
        <Button
          onClick={handleCreateTemplateDialogOpen}
          variant="outlined"
          sx={{width: '100%'}}
          endIcon={<AddCircleOutlineIcon />}
        >
          CREATE EXCEPTION TEMPLATE
        </Button>
      </Grid>
      <ScheduleExceptionsTable
        tableRefresh={tableRefresh}
        snackbar={snackbar}
        onTableRefresh={setTableRefresh}
      />
      {createTemplateDialogOpen && (
        <EditTemplateDialog
          open={createTemplateDialogOpen}
          handleClose={handleCreateTemplateDialogClose}
          snackbar={snackbar}
          onSaveSuccess={setTableRefresh}
        />
      )}
    </Fragment>
  );
};

ScheduleExceptionsSection.propTypes = {
  snackbar: PropTypes.shape({}).isRequired,
};

export default ScheduleExceptionsSection;
