import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  Grid,
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  Backdrop,
} from '@mui/material';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import PageHeader from '../pageHeader';
import ResponsiveDrawer from './ResponsiveDrawer';
import {toolbarMultiplier} from '../../util/general';
import SecondNav from './mainNavigation/SecondNav';
import {withResponsiveTheme} from './themeWrapper';

const PageTemplateResponsive = (props) => {
  const {
    PageHeaderProps,
    PageContentProps,
    category,
    title,
    drawerContent,
    children,
    actions,
    navigationItems,
    normalizeHeight,
    customIsActiveMatcher,
  } = props;
  const [open, setOpen] = useState(false);
  const [headerActions, setHeaderActions] = useState(actions);

  const theme = useTheme();

  const collapseDrawer = useCallback(() => setOpen(false), []);
  const expandDrawer = useCallback(() => setOpen(true), []);
  const handleToggleTempDrawer = () => setOpen((prev) => !prev);
  const location = useLocation();

  let pageTitle = category ? `${category} | ` : '';
  pageTitle += title ? `${title} | Envysion` : 'Envysion';

  // 1920px XL breakpoint minus 80px margins for XL screens (40px left + 40px right)
  // will leave us with 1840px container
  const maxWidth = 1840;

  const isMediumWindowOrBigger = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumWindow = useMediaQuery(theme.breakpoints.only('md'));
  const bottomNavigationBarHeight =
    navigationItems && navigationItems.length > 0
      ? theme.mixins.bottomNavigationBar.height
      : 0;

  const collapseDrawerIfMediumScreen = () => {
    if (isMediumWindow) {
      collapseDrawer();
    }
  };

  useEffect(
    () => {
      if (!isMediumWindowOrBigger) {
        collapseDrawer();
      }
    },
    [collapseDrawer, isMediumWindowOrBigger],
  );

  const calculateBoxHeight = () =>
    `calc(100vh - ${theme.mixins.toolbar.height *
      toolbarMultiplier(location.pathname, title)}px - ${
      isMediumWindowOrBigger ? 0 : bottomNavigationBarHeight
    }px)`;

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {title && (
        <PageHeader
          {...PageHeaderProps}
          displayDrawerButton={!!drawerContent}
          onToggleTempDrawer={handleToggleTempDrawer}
          actions={headerActions}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: normalizeHeight ? '100%' : calculateBoxHeight(),
        }}
        data-cy="page-template-content"
      >
        {drawerContent && (
          <ResponsiveDrawer
            openDrawer={open}
            onOpenDrawer={handleToggleTempDrawer}
            drawerHeight={calculateBoxHeight()}
            drawerContent={drawerContent}
          />
        )}
        <Backdrop
          data-cy="drawer-backdrop"
          open={open && isMediumWindow}
          onClick={collapseDrawer}
          sx={{
            zIndex: theme.zIndex.drawer - 1,
            display: 'flex',
            justifyItems: 'start',
          }}
        />
        {navigationItems && isMediumWindowOrBigger && (
          <ResponsiveDrawer
            openDrawer={open}
            onOpenDrawer={handleToggleTempDrawer}
            drawerHeight={calculateBoxHeight()}
            drawerContent={
              <SecondNav
                collapseDrawer={collapseDrawerIfMediumScreen}
                navigationItems={navigationItems}
                isOpenedNavigation={open}
                drawerHeight={calculateBoxHeight()}
                customIsActiveMatcher={customIsActiveMatcher}
              />
            }
            secondNavigation
          />
        )}
        <Box
          sx={{
            ...theme.mixins.scrollable(),
            flexGrow: 1,
            height: normalizeHeight ? '100%' : calculateBoxHeight(),
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid
            sx={{...PageContentProps}}
            container
            paddingLeft={
              isMediumWindow && (navigationItems || drawerContent) ? 9 : 0
            }
            marginY={{xs: 1, sm: 3, md: 3, lg: 3, xl: 3}}
            marginX={{xs: 2, sm: 2.5, md: 3, lg: 5}}
            maxWidth={maxWidth}
            justifyContent="center"
          >
            {typeof children === 'function'
              ? children(collapseDrawer, expandDrawer, setHeaderActions)
              : children}
          </Grid>
        </Box>
      </Box>

      {navigationItems && !isMediumWindowOrBigger && (
        <Box
          data-cy="bottom-navigation-bar"
          component={Paper}
          sx={{
            boxShadow: theme.shadows[5],
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
            borderRadius: 0,
          }}
        >
          <SecondNav
            navigationItems={navigationItems.filter(
              (item) => item.displayOnSmallScreen ?? true,
            )}
            customIsActiveMatcher={customIsActiveMatcher}
            bottom
          />
        </Box>
      )}
    </Fragment>
  );
};

PageTemplateResponsive.propTypes = {
  PageHeaderProps: PropTypes.shape({}),
  PageContentProps: PropTypes.shape({}),
  category: PropTypes.string,
  title: PropTypes.string,
  drawerContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  normalizeHeight: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  actions: PropTypes.node,
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      capabilities: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.node,
      primaryText: PropTypes.string,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string,
          capabilities: PropTypes.arrayOf(PropTypes.string),
          id: PropTypes.string,
          name: PropTypes.string,
          icon: PropTypes.node,
          primaryText: PropTypes.string,
        }),
      ),
    }),
  ),
  customIsActiveMatcher: PropTypes.func,
};

PageTemplateResponsive.defaultProps = {
  PageHeaderProps: {},
  PageContentProps: {},
  category: null,
  title: null,
  drawerContent: null,
  actions: null,
  navigationItems: null,
  normalizeHeight: false,
  customIsActiveMatcher: null,
};

export default withResponsiveTheme(PageTemplateResponsive);
