import React, {useState} from 'react';
import {Autocomplete, Box, Button, TextField} from '@mui/material';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

const applianceStatusFilters = ['Offline', 'Impaired', 'Online'];
const cameraStatusFilters = ['Offline', 'Online'];
const cloudArchiveStatusFilters = ['Error', 'Warning', 'Good'];
const uploadSpeedFilters = ['Offline', 'Poor', 'Good', 'Excellent'];
const impairedCamerasFilters = ['Impaired', 'Unimpaired'];

const formatFilters = (filters) => {
  if (isEmpty(filters)) {
    return {
      bitrateQuality: [],
      caStatus: [],
      cameraStatus: [],
      status: [],
      impairedCameras: [],
    };
  }
  const formattedFilters = {};
  Object.keys(filters).forEach((key) => {
    if (typeof filters[key] === 'string' && filters[key].length > 0) {
      formattedFilters[key] = filters[key].split(',').map(capitalize);
    } else {
      formattedFilters[key] = [];
    }
  });
  return formattedFilters;
};

const FilterPanel = (props) => {
  const {filterValues, onFilterChange, caStatusAllowed, loading} = props;
  const [filterModel, setFilterModel] = useState(formatFilters(filterValues));

  const handleApply = () => {
    onFilterChange(filterModel);
  };

  const handleClear = () => {
    setFilterModel({
      bitrateQuality: [],
      caStatus: [],
      cameraStatus: [],
      status: [],
      impairedCameras: [],
    });
  };

  const handleClose = () => {
    onFilterChange();
  };

  return (
    <Box sx={{width: '100%', p: 2, '& > .MuiAutocomplete-root': {mb: 1.5}}}>
      <Autocomplete
        multiple
        disablePortal
        id="appliance-status-filter"
        options={applianceStatusFilters}
        value={filterModel?.status}
        disabled={loading}
        onChange={(e, newValues) =>
          setFilterModel((prev) => ({...prev, status: newValues}))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Appliance Status"
            placeholder="Select..."
          />
        )}
      />
      <Autocomplete
        multiple
        disablePortal
        id="camera-status-filter"
        options={cameraStatusFilters}
        value={filterModel?.cameraStatus}
        disabled={loading}
        onChange={(e, newValues) =>
          setFilterModel((prev) => ({...prev, cameraStatus: newValues}))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Camera Status"
            placeholder="Select..."
          />
        )}
      />
      <Autocomplete
        multiple
        disablePortal
        id="camera-impaired-filter"
        options={impairedCamerasFilters}
        value={filterModel?.impairedCameras}
        onChange={(e, newValues) =>
          setFilterModel((prev) => ({...prev, impairedCameras: newValues}))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Image Health"
            placeholder="Select..."
          />
        )}
      />

      {caStatusAllowed && (
        <Autocomplete
          multiple
          disablePortal
          id="cloud-archive-status-filter"
          options={cloudArchiveStatusFilters}
          value={filterModel?.caStatus}
          disabled={loading}
          onChange={(e, newValues) =>
            setFilterModel((prev) => ({...prev, caStatus: newValues}))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Cloud Archive Status"
              placeholder="Select..."
            />
          )}
        />
      )}
      <Autocomplete
        multiple
        disablePortal
        id="upload-speed-filter"
        options={uploadSpeedFilters}
        value={filterModel?.bitrateQuality}
        disabled={loading}
        onChange={(e, newValues) =>
          setFilterModel((prev) => ({...prev, bitrateQuality: newValues}))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Upload Speed"
            placeholder="Select..."
          />
        )}
      />
      <Box sx={{display: 'flex', justifyContent: 'flex-end', pt: 1}}>
        <Button
          className="filter-clear-button"
          variant="text"
          sx={{mr: 2}}
          onClick={handleClear}
          disabled={loading}
        >
          Clear
        </Button>
        <Button
          className="filter-cancel-button"
          variant="text"
          sx={{mr: 2}}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="filter-apply-button"
          variant="contained"
          color="primary"
          onClick={handleApply}
          disabled={loading}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default FilterPanel;
