import 'core-js/shim';
import 'custom-event-polyfill';

if (
  !('IntersectionObserver' in window) ||
  !('IntersectionObserverEntry' in window) ||
  !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
) {
  // eslint-disable-next-line no-unused-expressions
  import('intersection-observer');
}

if (typeof Event !== 'function') {
  // eslint-disable-next-line no-undef
  window.Event = CustomEvent;
}
